<template>
  <QDialog
    v-model="authStore.showLoginModal"
    persistent
  >
    <div class="relative px-10 w-full md:w-sm lg:w-md dark:bg-dark-1 light:bg-light-1">
      <div class="py-8 flex justify-center">
        <Logo
          class="w-1/2"
          :dark="$q.dark.isActive"
        />
        <QBtn
          v-close-popup
          to="/"
          icon="close"
          flat
          round
          dense
          class="absolute top-2 right-2"
        />
      </div>
      <QForm
        @submit.prevent="authStore.login(form)"
      >
        <div class="flex flex-col gap-4">
          <QInput
            v-model="form.username"
            placeholder="아이디를 입력하세요"
            outlined
            dense
            :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
          />
          <QInput
            v-model="form.password"
            placeholder="비밀번호를 입력하세요"
            outlined
            dense
            :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
            type="password"
          />
        </div>
        <div class="mt-8 grid grid-cols-2 gap-4">
          <QBtn
            label="로그인"
            color="secondary"
            unelevated
            outline
            type="submit"
            :loading="authStore.logging"
          />
          <QBtn
            label="비회원문의"
            color="secondary"
            text-color="black"
            unelevated
            @click="authStore.showGuestQuestionModal = true"
          />
        </div>
        <div class="py-8 flex flex-col items-center">
          <div>아직 회원이 아니신가요?</div>
          <RouterLink
            :to="{name:'registration'}"
            class="text-primary"
          >
            회원가입 바로가기
          </RouterLink>
        </div>
      </QForm>
    </div>
  </QDialog>
</template>

<script setup>
import Logo from '@/components/Logo.vue'
import { useAuthStore } from '@/stores/auth.js'
import { reactive } from 'vue'

const authStore = useAuthStore()

const form = reactive({
  username: '',
  password: ''
})
</script>
