<template>
  <QDialog
    v-model="authStore.showInviteCodeModal"
    persistent
  >
    <div class="relative px-10 w-full md:w-sm lg:w-md dark:bg-dark-1 light:bg-light-1">
      <div class="py-8 flex justify-center">
        <Logo class="w-1/2" />
        <QBtn
          v-close-popup
          to="/"
          icon="close"
          flat
          round
          dense
          class="absolute top-2 right-2"
        />
      </div>
      <QForm
        @submit.prevent="submit"
      >
        <div class="flex flex-col gap-4">
          <QInput
            v-model="form.code"
            placeholder="추천인 코드를 입력하세요"
            outlined
            dense
            :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
          />
        </div>
        <div class="py-8 flex flex-col gap-4">
          <QBtn
            label="확인"
            color="secondary"
            text-color="black"
            dense
            unelevated
            type="submit"
            :loading="loading"
            class="w-full"
          />
        </div>
      </QForm>
    </div>
  </QDialog>
</template>

<script setup>
import Logo from '@/components/Logo.vue'
import { api } from '@/plugins/axios.js'
import { useAuthStore } from '@/stores/auth.js'
import { reactive, watch } from 'vue'
import { useRequest } from 'vue-request'
import { useRouter } from 'vue-router'
import { useSettingStore } from '@/stores/setting'

const router = useRouter()
const authStore = useAuthStore()
const settingStore = useSettingStore()
const form = reactive({
  code: null,
})

watch(settingStore, () => {
  form.code = settingStore.inviteCode
}, { immediate: true })

const { runAsync, loading } = useRequest(() => api.post('invite-codes/check', { code: form.code }))

async function submit () {
  await runAsync()
  authStore.showInviteCodeModal = false
  await router.push({ name: 'registration', params: { code: form.code } })
}
</script>
